import React, {
  useMemo,
} from 'react';
import styled from 'styled-components';

import AntdModal from 'antd/lib/modal';
import AntdRow from 'antd/lib/row';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import Admin from 'hive-admin';

import Types from '../../common/modules/types';

const Close = styled(PlusOutlined)`
  margin-top: env(safe-area-inset-top);
  transform: rotate(45deg);
  font-size: 140%;
`;

const ScannerPickerModal = styled(AntdModal)``;

const ScannerPickerModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  > .ant-row {
    width: 100%;
    > .ant-select {
      width: 100%;
      +span {
        top: 6px;
      }
    }
  }
`;

export default function ScannerPicker({
  userId,
  setUserId,
  isVisible,
  onOk,
  onCancel,
  client,
}) {
  const userField = useMemo(
    () => Admin.compileFromLibrary(['FieldConnectionSelect', {
      name: 'user',
      label: null,
      // prefix: 'User:',
      placeholder: 'Search platform users',
      allowClear: true,
      searchPaths: ['name'],
      url: '/users',
      getExtraQueryConditions: () => ([{
        role: { NE: Types.USER_ROLE_CONST.ADMIN },
        active: true,
      }]),
      getChoiceLabel: item => `${
        item.name
      } / ${
        Types.USER_ROLE_LABELS_MAP[item.role]
      }`,
    }]),
    [],
  );
  return (
    <ScannerPickerModal
      title="User Scanning"
      visible={isVisible}
      onOk={onOk}
      okText="Open Scanner"
      okButtonProps={{ disabled: !userId }}
      onCancel={onCancel}
      closeIcon={<Close />}
      destroyOnClose
    >
      <ScannerPickerModalWrapper>
        <AntdRow>
          {userField.render({
            form: null,
            value: userId,
            onChange: value => setUserId(value),
            client,
          })}
        </AntdRow>
      </ScannerPickerModalWrapper>
    </ScannerPickerModal>
  );
}
