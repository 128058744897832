import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
} from 'react';
import styled, { css, useTheme } from 'styled-components';
import { motion } from 'framer-motion';

import WebCam from 'react-webcam';

import QRCodeReader from 'qr-scanner';
// eslint-disable-next-line
import QrScannerWorkerPath from '!!file-loader!qr-scanner/qr-scanner-worker.min.js';

import {
  Space,
  Modal,
  Close,
  Image,
  Title,
  Subtitle,
  Description,
  Button,
} from '../Card';

import {
  Spacer,
} from '../../../common/components/ModalCard';

import Emoji from './Emoji';

import logoFullSrc from '../../../common/assets/logo-full.svg';

QRCodeReader.WORKER_PATH = QrScannerWorkerPath;

const WebCamWrapper = styled(motion.div)`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: ${({ color }) => (
    typeof color === 'object'
      ? `linear-gradient(to right, ${color[0]}, ${color[1]})`
      : color
  )};
  z-index: 9999999999;
  ${({ blur }) => blur === true && css`
    backdrop-filter: blur(5px);
  `}
  > video {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;

WebCamWrapper.defaultProps = {
  color: 'rgba(0, 0, 0, 0.4)',
  // blur: true,
};

const FrameWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-shrink: 0;
  width: 100%;
  border-radius: 30px;
  overflow: visible;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const Frame = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-shrink: 0;
  width: calc(100%);
  max-width: calc(350px - 110px);
  margin-top: 100px;
  overflow: hidden;
`;

const FrameSpacer = styled.div`
  width: 100%;
  padding-top: 100%;
  flex-shrink: 0;
`;

const FrameCorner = styled.div`
  position: absolute;
  width: 25%;
  height: 25%;
  pointer-events: none;
  &[data-corner="TL"] {
    top: 0px; left: 0px;
    border-top-left-radius: 30px;
    border-top: 2px solid white;
    border-left: 2px solid white;
  }
  &[data-corner="TR"] {
    top: 0px; right: 0px;
    border-top-right-radius: 30px;
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
  &[data-corner="BL"] {
    bottom: 0px; left: 0px;
    border-bottom-left-radius: 30px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
  }
  &[data-corner="BR"] {
    bottom: 0px; right: 0px;
    border-bottom-right-radius: 30px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }
`;

const ScannerWebCam = forwardRef(
  ({
    onScan,
    videoConstraints,
    ...props
  },
  ref,
) => {
    const cameraRef = useRef();
    useEffect(
      () => {
        let scanning = true;
        let timeout = null;
        const scan = async () => {
          if (scanning) {
            try {
              if (
                   cameraRef.current
                && cameraRef.current.video
                && cameraRef.current.video.videoWidth > 0
              ) {
                const result = await QRCodeReader.scanImage(
                  cameraRef.current.video,
                );
                if (result && scanning && onScan) {
                  // alert(result);
                  onScan(result);
                }
              }
            } catch (error) {
              // alert(error);
            }
            if (scanning && onScan) {
              clearTimeout(timeout);
              timeout = setTimeout(scan, 100);
            }
          }
        };
        scan();
        return () => {
          clearTimeout(timeout);
          scanning = false;
        };
      },
      [onScan],
    );
    return (
      <WebCamWrapper
        ref={ref}
        {...props}
      >
        <WebCam
          ref={cameraRef}
          audio={false}
          videoConstraints={videoConstraints}
        />
        <FrameWrapper>
          <Frame>
            <FrameSpacer />
            <FrameCorner data-corner="TL" />
            <FrameCorner data-corner="TR" />
            <FrameCorner data-corner="BL" />
            <FrameCorner data-corner="BR" />
          </Frame>
        </FrameWrapper>
      </WebCamWrapper>
    );
  },
);

ScannerWebCam.defaultProps = {
  videoConstraints: {
    // width: { ideal: 1080 * 2 },
    // height: { ideal: 1440 * 2 },
    facingMode: { ideal: 'environment' },
    focusMode: { ideal: 'continuous' },
  },
};

const ScannerSadSpacerImage = styled(motion.div)`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  height: 80vmin;
  transform: scale(0.9);
  align-items: center;
  justify-content: center;
`;

const EmojiSpacer = props => (
  <Spacer {...props}>
    <ScannerSadSpacerImage>
      <Emoji
        delay={4000}
      />
    </ScannerSadSpacerImage>
  </Spacer>
);

export default function ScannerCard({
  onScan,
  isVisible,
  isRegularFlow,
  cage,
  cages,
  user,
  onClose,
  onCageClick,
  setIsMapVisible,
  setIsRegularFlow,
  ...props
}) {
  const theme = useTheme();
  const closeButton = <Close onClick={onClose} />;
  const show = (
      isVisible
    ? (
          cage
        ? 'ScanYourStickersWithCage'
        : user.role === 'CUSTOMER'
        ? (
              user.tierSupportsPickup && !isRegularFlow
            ? (
                !user.area || !user.area.serviced
                ? 'ScanYourBagsNoAtHomePickup'
                : 'ScanYourBags'
              )
            : 'ScanTheCage'
          )
        : 'ReeScanner'
      )
    : null
  );
  const [isButtonActive, setIsButtonActive] = useState(false);
  useEffect(
    () => {
      setIsButtonActive(false);
      const timeout = setTimeout(() => setIsButtonActive(true), 3000);
      return () => {
        clearTimeout(timeout);
        setIsButtonActive(false);
      };
    },
    [show],
  );
  return (
    <>
      <Modal
        ComponentBackDrop={ScannerWebCam}
        backdropProps={{ onScan }}
        {...props}
        key="ScanYourStickersWithCage"
      >
        {
          show === 'ScanYourStickersWithCage'
          ? (
              <>
                {closeButton}
                <Image
                  src={
                    cage.organization.image
                    ? cage.organization.image.src
                    : logoFullSrc
                  }
                />
                <Title>Scan Your Stickers</Title>
                <Subtitle>To Place an Order</Subtitle>
                <Description>
                  Make sure to scan each bag of waste individually
                  in order to secure your points and get your
                  Reewards!
                </Description>
                <Space h={20} />
                <Button
                  title="DISMISS THIS CAGE"
                  onClick={() => onCageClick(null)}
                />
                <Space h={10} />
              </>
            )
          : null
        }
      </Modal>
      <Modal
        ComponentBackDrop={ScannerWebCam}
        backdropProps={{ onScan }}
        {...props}
        key="ScanYourStickers"
      >
        {
          show === 'ScanYourStickers'
          ? (
              <>
                {closeButton}
                <Image
                  src={
                    cage.organization.image
                    ? cage.organization.image.src
                    : logoFullSrc
                  }
                />
                <Title>Scan Your Stickers</Title>
                <Subtitle>To Place an Order</Subtitle>
                <Description>
                  Make sure to scan each bag of waste individually
                  in order to secure your points and get your
                  Reewards!
                </Description>
                <Space h={20} />
                <Button
                  title="DISMISS THIS CAGE"
                  onClick={() => onCageClick(null)}
                />
                <Space h={10} />
              </>
            )
          : null
        }
      </Modal>
      <Modal
        ComponentBackDrop={ScannerWebCam}
        backdropProps={{ onScan }}
        {...props}
        key="ScanYourBags"
      >
        {
          show === 'ScanYourBags'
          ? (
              <>
                {closeButton}
                <Image src={logoFullSrc} />
                <Title style={{ fontSize: '32px' }}>
                  Scan Your QR Sticker
                </Title>
                <Subtitle>Then Attach it to a Bag</Subtitle>
                <Description>
                  Make sure that each bag has its own
                  QR CODE sticker attached in order to track it!
                </Description>
                <Space h={20} />
                <Button
                  title="DROP OFF AT A CAGE"
                  // eslint-disable-next-line max-len
                  subtitle="Place the waste in one of the Ree cages instead!"
                  onClick={() => setIsRegularFlow(true)}
                />
                <Space h={10} />
              </>
            )
          : null
        }
      </Modal>
      <Modal
        ComponentBackDrop={WebCamWrapper}
        backdropOpacity={0.85}
        backdropColor={[theme.colors.brandLight, theme.colors.brandDark]}
        ComponentSpacer={EmojiSpacer}
        {...props}
        key="ScanYourBagsNoAtHomePickup"
      >
        {
          show === 'ScanYourBagsNoAtHomePickup'
          ? (
              <>
                {closeButton}
                <Image src={logoFullSrc} />
                <Title>
                  Ooops...
                </Title>
                <Subtitle>
                  No at door pickups
                  <br />
                  in your area yet
                </Subtitle>
                <Description>
                  We are working hard to introduce VIP, at door pickups in
                  your area.
                  Until then, you can use regular flow by
                  clicking the button below.
                </Description>
                <Space h={20} />
                <Button
                  title="DROP OFF AT A CAGE"
                  // eslint-disable-next-line max-len
                  subtitle="Place the waste in one of the Ree cages instead!"
                  background={
                      isButtonActive
                    ? theme.colors.brand
                    : undefined
                  }
                  onClick={() => setIsRegularFlow(true)}
                />
                <Space h={10} />
              </>
            )
          : null
        }
      </Modal>
      <Modal
        ComponentBackDrop={ScannerWebCam}
        backdropProps={{ onScan }}
        {...props}
        key="ScanTheCage"
      >
        {
          show === 'ScanTheCage'
          ? (
              <>
                {closeButton}
                <Image src={logoFullSrc} />
                <Title>Scan The Cage</Title>
                <Subtitle>To Start a Request</Subtitle>
                <Description>
                  In case you are leaving your bag in front of the
                  door for the building management to pick it up you
                  may choose your building from the map below.
                </Description>
                <Space h={20} />
                <Button
                  title="CHOOSE FROM THE MAP"
                  subtitle={`${
                    cages.length
                  } ${
                    cages.length === 1
                    ? 'cage'
                    : 'cages'
                  } available around you`}
                  onClick={() => setIsMapVisible(true)}
                />
                <Space h={10} />
              </>
            )
          : null
        }
      </Modal>
      <Modal
        ComponentBackDrop={ScannerWebCam}
        backdropProps={{ onScan }}
        {...props}
        key="ReeScanner"
      >
        {
          show === 'ReeScanner'
          ? (
              <>
                {closeButton}
                <Image src={logoFullSrc} />
                <Title>Ree Scanner</Title>
                <Subtitle>
                  Please point your camera towards the QR Code
                  so that it fills area marked above.
                </Subtitle>
              </>
            )
          : null
        }
      </Modal>
    </>
  );
}
