// import React from 'react';

export const columnName = {
  path: 'name',
  width: '100%',
  link: '/partners/:_id',
};

export const ADMIN = [
  columnName,
];
