/* eslint-disable react/no-unused-state */

import React, { useContext } from 'react';

import DefaultAdmin from 'hive-admin';
import CodeScanner, { ScannerContext, ScannerProvider } from './CodeScanner';

function AdminContent({
  adminProps,
  extraProps,
  renderContent,
}) {
  const { isVisible, setIsVisible } = useContext(ScannerContext);
  const content = renderContent(extraProps);
  if (content && adminProps.viewer) {
    return (
      <>
        {content}
        <CodeScanner
          {...adminProps}
          {...extraProps}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      </>
    );
  }
  return content;
}

export default class Admin extends DefaultAdmin {
  renderContent(extraProps = {}) {
    return (
      <ScannerProvider>
        <AdminContent
          adminProps={this.props}
          extraProps={extraProps}
          renderContent={(...args) => super.renderContent(...args)}
        />
      </ScannerProvider>
    );
  }
}
