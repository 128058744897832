import React, {
  // useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${({ padding }) => padding}px;

  .face {
    transform-box: fill-box;
    transform-origin: center;
    animation: face ${({ duration }) => duration}ms ${({ delay }) => delay}ms
      forwards ${({ easing }) => easing};
  }

  @keyframes face {
    100% {
      transform: scaleY(-1);
    }
  }

  .head {
    transform-box: fill-box;
    transform-origin: center;
    animation: head ${({ duration }) => duration}ms ${({ delay }) => delay}ms
      forwards ${({ easing }) => easing};
  }

  @keyframes head {
    100% {
      transform: translateY(-10%) rotate(-5deg);
    }
  }
`;

const Emoji = ({
  duration,
  delay,
  padding,
  onAnimationStart,
  onAnimationEnd,
  ...props
}) => {
  const emojiRef = useRef(null);

  // useEffect(() => {
  //   if (emojiRef && emojiRef.current) {
  //     const { current } = emojiRef;
  //     current.addEventListener(
  //       'animationstart',
  //       onAnimationStart,
  //       false
  //     );
  //     current.addEventListener('animationend', onAnimationEnd, false);
  //     return function cleanup() {
  //       current.removeEventListener(
  //         'animationstart',
  //         onAnimationStart,
  //         false
  //       );
  //       current.removeEventListener(
  //         'animationend',
  //         onAnimationEnd,
  //         false
  //       );
  //     };
  //   }
  //   return undefined;
  // }, [onAnimationStart, onAnimationEnd]);

  return (
    <Wrapper duration={duration} delay={delay} padding={padding} {...props}>
      <svg
        viewBox="0 0 800 800"
        fill="none"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="head">
          <path
            // eslint-disable-next-line max-len
            d="M647.51 230.537C643.847 225.2 636.555 223.845 631.218 227.506C625.882 231.17 624.527 238.465 628.188 243.801C703.43 353.441 689.679 501.379 595.495 595.564C487.676 703.385 312.246 703.385 204.427 595.564C152.198 543.336 123.434 473.894 123.434 400.031C123.434 326.169 152.198 256.727 204.426 204.496C298.517 110.405 446.362 96.5891 555.966 171.648C561.304 175.303 568.596 173.941 572.253 168.599C575.909 163.258 574.545 155.965 569.204 152.309C450.3 70.8799 289.92 85.8552 187.857 187.922C131.202 244.578 100 319.907 100 400.031C100 480.155 131.202 555.485 187.857 612.139C244.512 668.797 319.838 700 399.96 700C480.082 700 555.408 668.798 612.063 612.141C714.23 509.973 729.138 349.486 647.51 230.537V230.537Z"
            fill="white"
          />
          <path
            // eslint-disable-next-line max-len
            d="M596.586 208.649C600.719 211.4 606.281 211.225 610.225 208.205C613.934 205.363 615.632 200.393 614.408 195.876C613.097 191.037 608.738 187.474 603.723 187.203C598.749 186.932 594.028 189.971 592.204 194.602C590.204 199.679 592.04 205.615 596.586 208.649V208.649Z"
            fill="white"
          />
          <path
            className="face mouth"
            ref={emojiRef}
            // eslint-disable-next-line max-len
            d="M319.552 539.229C364.046 494.737 436.44 494.736 480.933 539.23C483.22 541.517 486.22 542.661 489.217 542.661C492.216 542.661 495.215 541.516 497.503 539.227C502.078 534.651 502.076 527.23 497.501 522.652C443.871 469.024 356.611 469.022 302.982 522.654C298.405 527.229 298.405 534.65 302.98 539.227C307.557 543.803 314.975 543.805 319.552 539.229V539.229Z"
            fill="white"
          />
          <path
            className="face eye-right"
            id="eye-right"
            // eslint-disable-next-line max-len
            d="M476.109 368.97C471.534 364.392 464.114 364.393 459.538 368.971C454.963 373.548 454.963 380.968 459.54 385.545C476.982 402.988 499.886 411.707 522.797 411.707C545.704 411.707 568.617 402.985 586.056 385.545C590.631 380.97 590.633 373.549 586.057 368.971C581.484 364.395 574.063 364.395 569.487 368.97C543.744 394.714 501.854 394.714 476.109 368.97V368.97Z"
            fill="white"
          />
          <path
            className="face eye-left"
            // eslint-disable-next-line max-len
            d="M341.431 368.97C336.855 364.392 329.437 364.391 324.861 368.969C299.117 394.712 257.226 394.714 231.481 368.969C226.905 364.391 219.486 364.393 214.91 368.97C210.335 373.546 210.336 380.967 214.912 385.544C232.351 402.985 255.261 411.705 278.17 411.705C301.08 411.705 323.989 402.985 341.429 385.544C346.006 380.968 346.007 373.548 341.431 368.97V368.97Z"
            fill="white"
          />
        </g>
      </svg>
    </Wrapper>
  );
};

Emoji.defaultProps = {
  padding: 0,
  delay: 1000,
  duration: 1000,
  easing: 'cubic-bezier(0.07, 0.85, 0.015, 1)',
};

export default Emoji;
