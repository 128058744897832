export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  rules: [
    ['validation.isRequired'],
  ],
}];

export const ADMIN = [
  fieldName,
];
