const colors = {
  brand: '#6ACA5F',
  brandDark: '#59C36A',
  brandLight: '#97DE3D',
  dark: '#27252F',
  light: '#FFFFFF',
  text: {
    brand: '#79CF51',
    // dark: '#383645',
    dark: '#4c4a5d',
    light: '#FFFFFF',
  },
};

const theme = {
  colors,
};

export default theme;
