import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  forwardRef,
} from 'react';
import styled, { css, useTheme } from 'styled-components';
import { motion } from 'framer-motion';

import {
  Space,
  Modal,
  Close,
  Image,
  Title,
  Subtitle,
  Description,
  Link,
  Button,
} from './Card';

import logoFullSrc from '../../common/assets/logo-full.svg';
import cageActiveSrc from '../../common/assets/icons/cage-active@3x.png';
import cageInactiveSrc from '../../common/assets/icons/cage-inactive@3x.png';

const MARKER_ICON = {
  scaledSize: new global.google.maps.Size(100, 110),
  anchor: new global.google.maps.Point(50, 90),
  origin: new global.google.maps.Point(0, 0),
  rotation: 0,
};

const MapWrapper = styled(motion.div)`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: ${({ color }) => (
    typeof color === 'object'
      ? `linear-gradient(to right, ${color[0]}, ${color[1]})`
      : color
  )};
  z-index: 9999999999;
  ${({ blur }) => blur === true && css`
    backdrop-filter: blur(5px);
  `}
  pointer-events: initial;
`;

const MapContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  pointer-events: initial;
`;

MapWrapper.defaultProps = {
  color: 'rgba(0, 0, 0, 0.4)',
  blur: true,
};

// const LocationButtonWrapper = styled(motion.div)`
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 50px;
//   height: 50px;
//   border-radius: 25px;
//   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.11);
//   background: ${({ theme }) => theme.colors.light};
//   top: -70px;
//   right: 20px;
// `;

// export function LocationButton({
//   onLocation,
// }) {
//   const theme = useTheme();
//   return (
//     <LocationButtonWrapper
//       whileTap={{ scale: 0.9 }}
//       onClick={() => {
//         if (navigator.geolocation) {
//           navigator.geolocation.getCurrentPosition(
//             ({ coords: { longitude: lng, latitude: lat } }) => {
//               onLocation({ lng, lat });
//             },
//           );
//         }
//       }}
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         height="24px"
//         viewBox="0 0 24 24"
//         width="24px"
//         fill={theme.colors.text.dark}
//       >
//         <path
//           d="M0 0h24v24H0V0z"
//           fill="none"
//         />
//         <path
//           d="M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z"
//         />
//       </svg>
//     </LocationButtonWrapper>
//   );
// }

export const GoogleMap = forwardRef(
  (
    {
      zoom,
      location,
      cage,
      cages,
      onCageClick,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    const mapContainerRef = useRef();
    const mapRef = useRef();
    const cagesRef = useRef([]);
    useEffect(
      () => {
        if (!mapRef.current) {
          mapRef.current = new global.google.maps.Map(
            mapContainerRef.current,
            {
              scaleControl: true,
              center: { ...location },
              zoom,
              disableDefaultUI: true,
              gestureHandling: 'greedy',
              clickableIcons: false,
              mapId: 'f7b88d1b7848c940',
            },
          );
          mapRef.current.addListener('click', () => onCageClick(null));
        }
        if (mapRef.current) {
          mapRef.current.panTo({ ...location });
          mapRef.current.setZoom(zoom);
          mapRef.current.panBy(0, 150);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [location, zoom],
    );
    useEffect(
      () => {
        cagesRef.current.forEach((marker) => {
          marker.setMap(null);
        });
        cagesRef.current = [];
        const bounds = new global.google.maps.LatLngBounds();
        cages.forEach((currentCage) => {
          bounds.extend({
            lng: currentCage.address.coordinates[0],
            lat: currentCage.address.coordinates[1],
          });
          const isActive = cage && currentCage._id === cage._id;
          const marker = new global.google.maps.Marker({
            icon: {
              ...MARKER_ICON,
              url: isActive ? cageActiveSrc : cageInactiveSrc,
            },
            position: {
              lng: currentCage.address.coordinates[0],
              lat: currentCage.address.coordinates[1],
            },
            title: currentCage.name,
            map: mapRef.current,
          });
          marker.setMap(mapRef.current);
          marker.addListener('click', (event) => {
            onCageClick(currentCage._id);
            event.stop();
          });
          cagesRef.current.push({ marker, cage: currentCage });
        });
        mapRef.current.fitBounds(bounds, 120);
        const timeout = setTimeout(() => {
          if (cages.length < 2) {
            mapRef.current.setZoom(zoom);
            mapRef.current.panBy(0, 100);
          }
        }, 300);
        return () => {
          clearTimeout(timeout);
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [cages],
    );
    useEffect(
      () => {
        cagesRef.current.forEach(({ cage: currentCage, marker }) => {
          const isActive = cage && currentCage._id === cage._id;
          marker.setIcon({
            ...MARKER_ICON,
            url: isActive ? cageActiveSrc : cageInactiveSrc,
          });
        });
      },
      [theme, cage],
    );
    return (
      <MapWrapper
        ref={ref}
        {...props}
      >
        <MapContainer
          ref={mapContainerRef}
        />
      </MapWrapper>
    );
  },
);

GoogleMap.defaultProps = {
  onCageClick: () => {},
};

export default function ScannerMap({
  isVisible,
  client,
  cage,
  cages,
  onClose,
  onCageClick,
  children,
  setIsMapVisible,
  ...props
}) {
  // eslint-disable-next-line no-unused-vars
  const [userLocation, setUserLocation] = useState({
    lng: 55.19885206855022,
    lat: 25.113897110259508,
  });
  const location = useMemo(
    () => (
      cage
      ? {
          lng: cage.address.coordinates[0],
          lat: cage.address.coordinates[1],
        }
      : userLocation
    ),
    [userLocation, cage],
  );
  return (
    <Modal
      ComponentBackDrop={GoogleMap}
      backdropProps={{
        zoom: 15,
        location,
        cage,
        cages,
        onCageClick,
      }}
      wrapperProps={{ style: { pointerEvents: 'none' } }}
      {...props}
    >
      {
        isVisible
        ? (
            <>
              <Close onClick={onClose} />
              {/* <LocationButton
                onLocation={coords => setUserLocation(coords)}
              /> */}
              {
                cage
                ? (
                    <>
                      <Image
                        onerror="this.style.display='none'"
                        src={
                          cage.organization.image
                          ? cage.organization.image.src
                          : logoFullSrc
                        }
                        alt=""
                      />
                      <Title>
                        {cage.organization.name}
                      </Title>
                      <Subtitle>
                        {cage.name}
                      </Subtitle>
                      <Description>
                        {cage.address.lines}
                      </Description>
                      <Link
                        href={
                          `https://www.google.com/maps/dir/?api=1&destination=${
                            cage.address.coordinates[1]
                          },${
                            cage.address.coordinates[0]
                          }`
                        }
                        target="_blank"
                      >
                        How do I get there?
                      </Link>
                      <Button
                        title="CONTINUE"
                        onClick={() => setIsMapVisible(false)}
                      />
                    </>
                  )
                : (
                    <>
                      <Image src={logoFullSrc} />
                      <Title>Choose The Cage</Title>
                      <Subtitle>To Start a Request</Subtitle>
                    </>
                  )
              }
              <Space h={10} />
            </>
          )
        : null
      }
    </Modal>
  );
}
