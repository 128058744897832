import {
  stringify as stringifyQuery,
  parse as parseQuery,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'query-string';

// eslint-disable-next-line import/prefer-default-export
export function getLinkWithSearchFields(
  location,
  fields = [],
  extras = {},
) {
  const search = parseQuery(
    location
    ? location.search.slice(1)
    : ''
  );
  fields.forEach(({ name, value, replace = false }) => {
    if (replace) {
      search[name] = value;
    } else {
      search[name] = (
        !search[name]
        ? []
        : Array.isArray(search[name])
        ? search[name]
        : [search[name]]
      )
      .filter(testValue => (
        Array.isArray(value)
        ? !value.includes(testValue)
        : value !== testValue
      ));
      search[name].push(...Array.isArray(value) ? value : [value]);
    }
  });
  return { ...location, ...extras, search: `?${stringifyQuery(search)}` };
}
